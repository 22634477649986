
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';

import type Day from '@/modules/common/types/day.type';
import type RatesCheckinDayModel from '@/modules/rates/models/rates-checkin-day.model';
import type DocumentFiltersModel from '@/modules/document-filters/models/document-filters.model';

import ClusterRatesService, { ClusterRatesServiceS } from '@/modules/cluster/cluster-rates.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';

import CommonDayPopup from './common-day-popup/index.vue';
import RatesDocumentModel from '../../rates/models/rates-document.model';

@Component({
    components: { CommonDayPopup },
})
export default class ClusterRatesDayPopup extends Vue {
    /** This component is only for regular cluster rates page popup */

    @inject(ClusterRatesServiceS) private clusterRatesService!: ClusterRatesService;
    @inject(HotelsServiceS) private hotelsService!: HotelsService;
    @inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;

    @Prop({ type: Number })
    day!: Day;

    get documents() {
        // [TODO] if no doc in hotels, close popup

        const { hotels } = this.clusterRatesService;

        if (!hotels) {
            return null;
        }

        const hotelId = parseInt(this.$route.params.hotelId, 10);
        const clusterHotelDocument = hotels.find(hotel => hotel.hotelId === hotelId!)?.compsetMain || null;

        if (!clusterHotelDocument) {
            return null;
        }

        const ratesDocument = new RatesDocumentModel();
        ratesDocument.id = Number(clusterHotelDocument.documentId);
        ratesDocument.checkinDates = clusterHotelDocument.checkinDates as Record<Day, RatesCheckinDayModel>;
        ratesDocument.currency = clusterHotelDocument.currency;
        ratesDocument.providerName = clusterHotelDocument.providerName;
        ratesDocument.updateDate = clusterHotelDocument.updateDate;
        ratesDocument.hotelNames = this.clusterRatesService.storeState.hotelNamesMap;

        return {
            main: ratesDocument,
        } as Record<string, RatesDocumentModel | null>;
    }

    get compset() {
        const { hotels } = this.clusterRatesService;

        if (!hotels) {
            return null;
        }

        const hotelId = parseInt(this.$route.params.hotelId, 10);
        const hotel = hotels.find(hotel => hotel.hotelId === hotelId!) || null;

        if (!hotel) {
            return null;
        }

        const compset = hotel.compsets.find(c => !!c.id && c.id === hotel.compsetMain?.id);

        return compset || null;
    }

    get isDocumentLoading() {
        // If no documument on cluster page, then close popup
        return false;
    }

    get hotelColors() {
        if (!this.compset) { return null; }
        return this.hotelsService.getHotelsGraphColor(this.compset.id);
    }

    get hotelColorsRgb() {
        if (!this.compset) { return null; }
        return this.hotelsService.getHotelsGraphColorRgb(this.compset.id);
    }

    get documentSettings() {
        const {
            year, month, priceShown, los,
        } = this.documentFiltersService;
        const documentSettings = this.compset
            ? {
                year,
                month,
                priceShown,
                compsetId: this.compset.id,
                los,
                pos: this.compset.mainPos || (this.compset.pos.length ? this.compset.pos[0] : null),
                competitors: this.compset.competitors,
            } as DocumentFiltersModel
            : null;
        return documentSettings;
    }

    get ratesSettings() {
        return {
            main: this.clusterRatesService.currentSettings,
        };
    }
}
