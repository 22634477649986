
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';

import type Day from '@/modules/common/types/day.type';
import DocumentFiltersModel from '@/modules/document-filters/models/document-filters.model';
import RatesProviderDataModel, { RatesCheckinDayAll } from '@/modules/rates/models/rates-provider-data.model';

import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import ClusterDiLiteService, { ClusterDiLiteServiceS } from '@/modules/cluster/cluster-di-lite.service';

import RatesDocumentAllModel from '../../rates/models/rates-document-all.model';
import CommonDayPopup from './common-day-popup/index.vue';

@Component({
    components: { CommonDayPopup },
})
export default class ClusterAllChannelsDayPopup extends Vue {
    /** This component is only for all channels (di lite) cluster rates page popup */

    @inject(HotelsServiceS) private hotelsService!: HotelsService;
    @inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @inject(ClusterDiLiteServiceS) private clusterDiLiteService!: ClusterDiLiteService;

    @Prop({ type: Number })
    day!: Day;

    get documents() {
        // [TODO] if no doc in hotels, close popup

        const { hotels } = this.clusterDiLiteService;

        if (!hotels) {
            return null;
        }

        const hotelId = parseInt(this.$route.params.hotelId, 10);
        const clusterHotelDocument = hotels.find(hotel => hotel.hotelId === hotelId!)?.compsetMain || null;

        if (!clusterHotelDocument || !clusterHotelDocument.checkinDates) {
            return null;
        }

        const ratesDocument = Object.assign(new RatesDocumentAllModel(), {
            id: Number(clusterHotelDocument.documentId),
            checkinDates: Object.keys(clusterHotelDocument.checkinDates).reduce((dayAcc, day) => {
                const numDay = Number(day);
                return {
                    ...dayAcc,
                    [numDay]: clusterHotelDocument.checkinDates![numDay]
                        ? Object.keys(clusterHotelDocument.checkinDates![numDay]?.hotels || {}).reduce((providerAcc, provider) => ({
                            ...providerAcc,
                            [provider]: clusterHotelDocument.checkinDates![numDay]!.hotels[provider] as RatesProviderDataModel,
                        }), {} as Record<string, RatesProviderDataModel>)
                        : null,
                };
            }, {} as Record<Day, RatesCheckinDayAll | null>),

            currency: clusterHotelDocument.currency,
            providerName: clusterHotelDocument.providerName,
            updateDate: clusterHotelDocument.updateDate,
            hotelNames: this.clusterDiLiteService.storeState.hotelNamesMap,
            updateDates: clusterHotelDocument.updateDates,
        });

        return {
            main: ratesDocument,
        } as Record<string, RatesDocumentAllModel | null>;
    }

    get compset() {
        const { hotels } = this.clusterDiLiteService;

        if (!hotels) {
            return null;
        }

        const hotelId = parseInt(this.$route.params.hotelId, 10);
        const hotel = hotels.find(hotel => hotel.hotelId === hotelId!) || null;

        if (!hotel) {
            return null;
        }

        const compset = hotel.compsets.find(c => !!c.id && c.id === hotel.compsetMain?.id);

        return compset || null;
    }

    get isDocumentLoading() {
        // If no documument on cluster page, then close popup
        return false;
    }

    get hotelColors() {
        if (!this.compset) { return null; }
        return this.hotelsService.getHotelsGraphColor(this.compset.id);
    }

    get hotelColorsRgb() {
        if (!this.compset) { return null; }
        return this.hotelsService.getHotelsGraphColorRgb(this.compset.id);
    }

    get documentSettings() {
        const {
            year, month, priceShown, los,
        } = this.documentFiltersService;
        const documentSettings = this.compset
            ? {
                year,
                month,
                priceShown,
                compsetId: this.compset.id,
                los,
                pos: this.compset.mainPos || (this.compset.pos.length ? this.compset.pos[0] : null),
                competitors: this.compset.competitors,
            } as DocumentFiltersModel
            : null;
        return documentSettings;
    }

    get ratesSettings() {
        return {
            main: this.clusterDiLiteService.settings,
        };
    }
}
